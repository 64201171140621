import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import { useSelector } from 'react-redux';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboards/Dashboard')));

const Error = Loadable(lazy(() => import('../views/authentication/Error')));

// Pages
const UserList = Loadable(lazy(() => import('../views/apps/Users-list/UsersList')));
const Rolelist = Loadable(lazy(() => import('../views/apps/Role-list/RoleList')));
const PermissionsList = Loadable(lazy(() => import('../views/apps/Permissions/Permissions')));

// Order
const Order = Loadable(lazy(() => import('../views/apps/Order/Order')));
const QRCode = Loadable(lazy(() => import('../views/apps/QRCode/QRCode')));
const AddOrder = Loadable(lazy(() => import('../views/apps/Order/AddOrder')));
const EditOrder = Loadable(lazy(() => import('../views/apps/Order/EditOrder')));

const Client = Loadable(lazy(() => import('../views/apps/Client/Client')));
const ModelsList = Loadable(lazy(() => import('../views/apps/Models/ModelsList')));
const PartsList = Loadable(lazy(() => import('../views/apps/Parts-list/PartsList')));
const DesignList = Loadable(lazy(() => import('../views/apps/Design-List/DesignList')));
const VendorList = Loadable(lazy(() => import('../views/apps/Vendor-List/VendorList')));
const QRCodeDetails = Loadable(lazy(() => import('../views/apps/Order/QRCodeDetails')));
const Mis = Loadable(lazy(() => import('../views/apps/MIS/MIS')));
const Products = Loadable(lazy(() => import('../views/apps/Products/Products/Products')));
const ProductDetails = Loadable(
  lazy(() => import('../views/apps/Products/Products/ProductDetails')),
);
const ProductCatalogue = Loadable(
  lazy(() => import('../views/apps/ProductCatalogue/ProductCatalogue')),
);

const Designation = Loadable(lazy(() => import('../views/apps/Designation/Designation')));
const Department = Loadable(lazy(() => import('../views/apps/Department/Department')));
const GatePass = Loadable(lazy(() => import('../views/apps/GatePass/GatePass')));
const TaxiPass = Loadable(lazy(() => import('../views/apps/TaxiPass/TaxiPass')));

// Login
const Login = Loadable(lazy(() => import('../views/authentication/login/Login')));
const PrivacyPolicy = Loadable(
  lazy(() => import('../views/authentication/privacyPolicy/PrivacyPolicy')),
);
const DeletePolicy = Loadable(lazy(() => import('../views/authentication//delete/Delete')));

const privateRoute = {
  path: '/',
  element: <FullLayout />,
  children: [
    { path: '/', element: <Navigate to="/dashboard" /> },
    { path: '/dashboard', exact: true, element: <Dashboard /> },
    { path: '/users', exact: true, element: <UserList /> },
    { path: '/roles', exact: true, element: <Rolelist /> },
    { path: '/permissions', exact: true, element: <PermissionsList /> },
    { path: '/order', exact: true, element: <Order /> },
    { path: '/qrcode', exact: true, element: <QRCode /> },
    { path: '/add-order', exact: true, element: <AddOrder /> },
    { path: '/edit-order/:orderId', exact: true, element: <EditOrder /> },
    { path: '/client', exact: true, element: <Client /> },
    { path: '/models', exact: true, element: <ModelsList /> },
    { path: '/parts-list', exact: true, element: <PartsList /> },
    { path: '/design-list', exact: true, element: <DesignList /> },
    { path: '/vendor-list', exact: true, element: <VendorList /> },
    { path: '/qrcode-details/:userId', exact: true, element: <QRCodeDetails /> },
    { path: '/mis', exact: true, element: <Mis /> },

    // Product routes
    { path: '/products', exact: true, element: <Products /> },
    { path: '/products/products-details/:productId', exact: true, element: <ProductDetails /> },

    // ProductCatalogue
    { path: '/products-catalogue', exact: true, element: <ProductCatalogue /> },

    // Designation
    { path: '/designation', exact: true, element: <Designation /> },

    // Department
    { path: '/department', exact: true, element: <Department /> },

    // GatePass
    { path: '/gate-pass', exact: true, element: <GatePass /> },
    { path: '/taxi-pass', exact: true, element: <TaxiPass /> },

    {
      path: '/products-catalogue/products-details/:productId',
      exact: true,
      element: <ProductDetails />,
    },

    //404 page
    { path: '*', element: <Error /> },
  ],
};

const publicRoute = {
  path: '/',
  element: <BlankLayout />,
  children: [
    { path: '/', element: <Navigate to="/auth/login" /> },
    { path: '/auth/login', element: <Login /> },
    { path: '/privacy-policy', element: <PrivacyPolicy /> },
    { path: '/deletion-policy', element: <DeletePolicy /> },

    //404 page
    { path: '*', element: <Navigate to="/auth/login" replace /> },
  ],
};

const Router = () => {
  const { token } = useSelector((state: any) => state.auth);

  return token ? [privateRoute] : [publicRoute];
};

export default Router;
